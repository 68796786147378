body {
  margin: 0;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{font-family: 'Roboto', sans-serif;}

/* Calendar Datepicker */
.calendar-from-date {
  position: absolute;
  z-index: 99;
  height: 20px;
  right: 6px;
  background: white;
}
.ant-modal-close-x {
  display: none !important;
}
/* BULK LOAD */
.download-file {
  padding: 0 !important;
}
.download-file a {
    color: white;
    display: block;
    height: 35px;
    padding: 10px;
}

.download-file a:hover {
    color: white;
    display: block;
    height: 35px;
    padding: 10px;
}

@media (max-width: 767px) {
  .download-file {
    position: absolute !important;
    top: -65px;
    margin: 0px !important;
  }
}

.aplicar-bulkLoad-filters {
  width: 100% !important;
  margin: auto !important;
}
.wrap_filters {
  margin-right: -30px;
}
.msg-result-not-valid, .msg-result-dont-upload {
  display: block;
  width: 100%;
  float: right;
  text-align: center;
  color: red;
}
.msg-result-upload {
  display: block;
  width: 100%;
  float: right;
  text-align: center;
  color: green;
}
.strgTxt {
  font-weight: bolder;
  margin-top: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.right-side {
  right: 0;
  position: absolute;
  margin: 0;
  font-size: 13px;
}

.mid-container {
  display: inline-flex;
}
#last-pan {
  width: 85%;
  float: right
}
.last-pan {
  margin-left: 15px;
}
.first-td {
  min-width: 200px;
}
h1.text-center {
  text-align: center;
}
.img-merchants-brands {
  width: 28px;
  display: inline-flex;
  margin: 0 5px;
}
img.brand-query {
  width: 25px;
  display: inline-flex;
  margin-left: 5px;
}
.left-arrow-users {
  color: white;
  font-size: 1.5em;
}
td.amount {
  text-align: center !Important;
}
table#merchants-table {
  /* width: auto !important; */
}
.merchants-light {
  background: none !important;
}
table#merchants-table tbody tr {
  background-color: #F4F4F4 !important;
}
td.brand {
  width: 190px;
  min-width: 200px;
}

img.user-img-profile {
  margin-top: 0px;
}
.hero__header-change-picture-acquirer .media {
  max-width: 100%;
  position: relative;
  height: 130px;
  top: 0;
  text-align: center;
  padding: 0px 270px
}
.change-picture-acquirer .home__container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0;
}
.hero__header-change-picture-acquirer img {
  width: 45px;
}
label.label-img {
  width: 50px !important;
    margin: 0 0 0 15px !important;
}
.lbl-txt-img {
  width: 100% !important;
  max-width: 190px !important;
  padding-top: 15px;  
    margin: 0 5px !important;
}
.userAvatarForm.lbl{
  display: inline-flex;
  width: 100%;
}
.hero__header-change-picture-acquirer input {
  opacity: 0;
    position: absolute;
    width: 0;
}
.close-modal-acq-pic {
  position: absolute;
  margin: -10px 15px;
  cursor: pointer;
  right: 0;
  z-index: 99;
}
@media only screen and (min-width: 992px) {
  .change-picture-acquirer li.cardAdq__item a {
    max-width: 360px !important;
    margin: 1rem auto;
  }
}
img.user-img-app{
  border-radius: 50%;
    margin-top: 0;
}
.navHeaderMenu__title {
  padding-top: 10%;
}
.reesent {
  color: #D11990;
}

.disabled-section {
  width: 50px;
  height: 50px;
  background-color: #f5f5f5 !important;
  cursor: not-allowed;
  position: relative;
}

.disabled {
  background-color: #f5f5f5 !important;
  pointer-events: none;
}


.disabled-element::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  box-sizing: border-box;
}

.disabled-element::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 70%;
  height: 2px;
  background-color: white;
}

select.disabled-section[name="f-protocolo-brand-new"] {
	width:  50%;
	height: 50%;
	background-color: #f5f5f5;
	cursor: not-allowed;
	position: relative;

}

.iti__selected-flag.visor {
	background-color: #f5f5f5 !important;
	pointer-events: none;
}
